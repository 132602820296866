(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/drawer/assets/javascripts/drawer.js') >= 0) return;  svs.modules.push('/components/ui/drawer/assets/javascripts/drawer.js');

'use strict';

svs.ui = svs.ui || {};


svs.ui.Drawer = class Drawer {
  constructor(options) {
    const eventNotifier = new svs.core.Events(() => {});
    this.position = options.position || 'left';
    this.autoFold = options.autoFold || false;
    this.content = options.content;
    this.class = options.class;
    this.disableDimmerClick = (options === null || options === void 0 ? void 0 : options.disableDimmerClick) || false;
    this.isDimmerDisabled = options.isDimmerDisabled || false;
    this.id = new Date().getTime() + Math.floor(Math.random() * 9999);
    this.drawerId = "drawer-".concat(this.position, "-").concat(this.id);
    this.dimmerId = "drawer-dimmer-".concat(this.position, "-").concat(this.id);
    this.el = typeof options.el === 'string' ? document.querySelector(options.el) : options.el;
    this.dimmerEl = typeof options.el === 'string' ? document.querySelector(options.dimmerEl) : options.dimmerEl;
    this.body = document.body;
    this.on = eventNotifier.on;
    this.off = eventNotifier.off;
    this.trigger = eventNotifier.trigger;
    this.isSheet = options.isSheet || false;
    this.render();
    this.initEvents();
  }
  render() {
    if (!this.dimmerEl && !this.isDimmerDisabled) {
      const dimmerHtml = svs.ui.drawer.partials.dimmer({
        autoFold: this.autoFold,
        id: this.dimmerId,
        isSheet: this.isSheet,
        position: this.position,
        disableDimmerClick: this.disableDimmerClick
      });
      this.body.insertAdjacentHTML('beforeend', dimmerHtml);
      this.dimmerEl = document.getElementById(this.dimmerId);
    }
    if (!this.el) {
      const drawerHtml = svs.ui.drawer.partials.drawer({
        autoFold: this.autoFold,
        class: this.class,
        content: this.content,
        id: this.drawerId,
        isSheet: this.isSheet,
        position: this.position
      });
      this.body.insertAdjacentHTML('beforeend', drawerHtml);
      this.el = document.getElementById(this.drawerId);
    }
  }
  initEvents() {
    if (this.dimmerEl && !this.isDimmerDisabled && !this.disableDimmerClick) {
      this.dimmerEl.addEventListener('click', () => {
        this.close();
      });
    }
  }
  showDimmer() {
    this && this.dimmerEl && this.dimmerEl.classList.add("drawer-dimmer-position-".concat(this.position, "-open"));
  }
  hideDimmer() {
    this && this.dimmerEl && this.dimmerEl.classList.remove("drawer-dimmer-position-".concat(this.position, "-open"));
  }
  open() {
    if (!this.el.classList.contains("drawer-position-".concat(this.position, "-open"))) {
      this.trigger('open');
      const animationEndEvent = () => {
        this.el.removeEventListener('webkitAnimationEnd', animationEndEvent);
        this.el.removeEventListener('oAnimationend', animationEndEvent);
        this.el.removeEventListener('msAnimationEnd', animationEndEvent);
        this.el.removeEventListener('animationend', animationEndEvent);
        this.el.classList.remove("drawer-position-".concat(this.position, "-animate-open"));
        this.trigger('opened');
      };
      this.el.addEventListener('webkitAnimationEnd', animationEndEvent);
      this.el.addEventListener('oAnimationend', animationEndEvent);
      this.el.addEventListener('msAnimationEnd', animationEndEvent);
      this.el.addEventListener('animationend', animationEndEvent);
      this.el.classList.add("drawer-position-".concat(this.position, "-animate-open"));
      this.el.classList.add("drawer-position-".concat(this.position, "-open"));
      if (!this.isDimmerDisabled) {
        this.showDimmer();
      }
    }
  }
  close() {
    if (this.el.classList.contains("drawer-position-".concat(this.position, "-open"))) {
      this.trigger('close');
      const animationEndEvent = () => {
        this.el.removeEventListener('webkitAnimationEnd', animationEndEvent);
        this.el.removeEventListener('oAnimationend', animationEndEvent);
        this.el.removeEventListener('msAnimationEnd', animationEndEvent);
        this.el.removeEventListener('animationend', animationEndEvent);
        this.el.classList.remove("drawer-position-".concat(this.position, "-animate-close"));
        this.trigger('closed');
      };
      this.el.addEventListener('webkitAnimationEnd', animationEndEvent);
      this.el.addEventListener('oAnimationend', animationEndEvent);
      this.el.addEventListener('msAnimationEnd', animationEndEvent);
      this.el.addEventListener('animationend', animationEndEvent);
      this.el.classList.add("drawer-position-".concat(this.position, "-animate-close"));
      this.el.classList.remove("drawer-position-".concat(this.position, "-open"));
      if (!this.isDimmerDisabled) {
        this.hideDimmer();
      }
    }
  }
  remove() {
    if (this.el) {
      this.el.remove();
    }
    if (this.dimmerEl) {
      this.dimmerEl.remove();
    }
    this.trigger('remove');
  }
};

 })(window);